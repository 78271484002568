<template>
  <!-- 
    画面: エリア新規登録
    用途: エリアを新規登録する
    TODO:APIや画面両方に今後修正が入ってくる
   -->
  <div>
   <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("areaEditPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody class="px-5">
            <div class="area-edit">
              <CRow>
                <CCol>
                  <h6 class="mb-3"><strong>日本語</strong></h6>
                  <strong class="form_label">{{
                    $t("areaEditPage.id")
                  }}</strong>
                  <CInput
                    type="number"
                    v-model="currentID"
                    :disabled="isDisabled"
                  />
                  <strong class="form_label">{{
                    $t("areaEditPage.name")
                  }}</strong>
                  <CInput 
                    :plaintext="true"
                    v-model="EditAreaJa.name" />
                  <strong class="form_label">{{
                    $t("areaEditPage.pronunciation")
                  }}</strong>
                  <CInput 
                    :plaintext="true"
                    v-model="EditAreaJa.pronunciation" />
                  <strong class="form_label">{{
                    $t("areaEditPage.description")
                  }}</strong>
                  <CTextarea 
                    :plaintext="true"
                    v-model="EditAreaJa.description" />
                </CCol>
                <CCol>
                  <CTabs>
                    <CTab :active="true">
                      <template slot="title"> English </template>
                      <strong class="form_label">ID</strong>
                      <CInput
                        v-model="currentID"
                        disabled
                      />
                      <strong class="form_label">Name</strong>
                      <CInput 
                        :plaintext="true"
                        v-model="EditAreaEn.name"
                      />
                      <strong class="form_label">Japanese Name</strong>
                      <CInput
                        :plaintext="true" 
                        v-model="EditAreaEn.pronunciation" />
                      <strong class="form_label">Info</strong>
                      <CTextarea 
                        :plaintext="true"
                        v-model="EditAreaEn.description" />
                    </CTab>
                    <CTab>
                      <template slot="title"> 中文 </template>
                      <strong class="form_label">ID</strong>
                      <CInput
                        v-model="EditAreaZh.id"
                        disabled
                      />
                      <strong class="form_label">Name (Chinese)</strong>
                      <CInput 
                        :plaintext="true"
                        v-model="EditAreaZh.name" />
                      <strong class="form_label">Pronunciation(Chinese)</strong>
                      <CInput 
                        :plaintext="true"
                        v-model="EditAreaZh.pronunciation" />
                      <strong class="form_label">Info (Chinese)</strong>
                      <CTextarea 
                        :plaintext="true"
                        v-model="EditAreaZh.description" />
                    </CTab>
                  </CTabs>
                </CCol>
              </CRow>
            </div>
            </CCardBody>
            <CCardFooter>
               <!-- #region 戻るボタン -->
                <CButton
                  color="secondary"
                  @click="onBackClicked()"
                ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
                <!-- #endregion 戻るボタン -->
                <!-- TODO:確認専用画面のため、ボタン非表示 -->
              <!-- <CButton
                @click="saveAreaData()"
                color="success"
              class="float-right"
              v-bind:class="activeStatus"
            >
              <i class="icon cil-save mr-1"></i> {{
              $t("common.update") }}
            </CButton> -->
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>

      <CModal
        color="success"
        :closeOnBackdrop="false"
        :centered="true"
        :show.sync="successModal"
      >
        {{ $t("commonMessage.updateSuccessText") }}
        <template #header> {{ $t("commonMessage.updateSuccessHeader") }}
        </template>
        <template #footer>
          <CButton
            @click="successModal = false"
            color="secondary"
          >
            <i class="icon cil-x"></i> {{$t('common.close')}}
          </CButton>
        </template>
      </CModal>
      <CModal
        color="danger"
        closeOnBackdrop
        :centered="true"
        :show.sync="errorModal"
      >
        <p>{{ errorMessage }}</p>
        <template #header>
          <h4 class="modal-title">{{$t('common.error')}}</h4>
        </template>
        <template #footer>
          <CButton
            @click="errorModal = false"
            color="secondary"
          >
            <i class="icon cil-x"></i> {{$t('common.close')}}

          </CButton>
        </template>
      </CModal>

  </div>
</template>
<script>
import i18n from "@/i18n";

export default {
  name: "AreaList",
  data() {
    return {
      loading: false,
      successModal: false,
      errorModal: false,
      isDisabled: true,
      EditAreaJa: {},
      EditAreaEn: {},
      EditAreaZh: {},
      currentID: "",
      errorMessage: "",
      currentArea: [],
      newArea: [],
      TableFields: [
        {
          key: "id",
          label: `${i18n.t("areaEditPage.id")}`,
          _classes: "text-center",
          _style: "width:7%",
        },
        {
          key: "name",
          label: `${i18n.t("areaEditPage.name")}`,
          _classes: "text-center",
          _style: "width:14%",
        },
        {
          key: "pronunciation",
          label: `${i18n.t("areaEditPage.pronunciation")}`,
          _classes: "text-center",
        },
        {
          key: "description",
          label: `${i18n.t("areaEditPage.description")}`,
          _classes: "text-center",
        },
        {
          key: "operation",
          label: "",
          sorter: false,
          filter: false,
          _style: "width:5%",
          _classes: "text-center",
        },
      ],
    };
  },
  computed: {
    /** 入力チェック */
    // TODO:開発時コメントアウト
    // activeStatus() {
    //   return this.$v.edit.$invalid ? "inactive" : "active";
    // },

    areaId() {
      return this.$route.params.areaId;
    },

  },

  methods: {

    //#region Request
    reqGet() {
      const callback = (data) => {
        const areaJa = data.find((a) => a.language === "ja");
        const areaEn = data.find((a) => a.language === "en");
        const areaZh = data.find((a) => a.language === "zh");

        this.EditAreaJa = {
          id: areaJa.id,
          name: areaJa.name,
          description: areaJa.description,
        };
        this.EditAreaEn = {
          id: areaEn.id,
          name: areaEn.name,
          description: areaEn.description,
        };
        this.EditAreaZh = {
          id: areaZh.id,
          name: areaZh.name,
          description: areaZh.description,
        };
        this.loading = false;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
        this.loading = false;
      };

      this.reqGetArea(callback, errorCallback);
    },

    /**
     * push structure for empty language object
     */
    lgDataStructure(CId) {
      this.currentArea.find((a) => a.language === "ja")
        ? ""
        : this.currentArea.push({
            language: "ja",
            id: CId,
          });
      this.currentArea.find((a) => a.language === "en")
        ? ""
        : this.currentArea.push({
            language: "en",
            id: CId,
          });
      this.currentArea.find((a) => a.language === "zh")
        ? ""
        : this.currentArea.push({
            language: "zh",
            id: CId,
          });

      this.EditAreaJa = this.currentArea.find((a) => a.language === "ja");
      this.EditAreaEn = this.currentArea.find((a) => a.language === "en");
      this.EditAreaZh = this.currentArea.find((a) => a.language === "zh");

      this.currentID = CId;
    },
    // /** submit area data */
    // saveAreaData() {
    //   this.currentArea.map((el) => {
    //     el.id = this.currentID;
    //   });
    //   const urlRequest = "/rest/area/" + this.currentID;

    //   axios
    //     .put(urlRequest, this.currentArea, {
    //       headers: { "Accept-Language": "*" },
    //     })
    //     .then((resp) => {
    //       console.log(resp);
    //       this.successModal = true;
    //     })
    //     .catch((err) => {
    //       this.errorMessage = err;
    //       this.errorModal = true;
    //     });
    // },
    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.go(-1);
    },
  },
  beforeMount() {
    this.reqGet();
  },
};
</script>